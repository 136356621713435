import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "./styles";
import { MDBTable, MDBTableBody, MDBTableHead, MDBRow } from "mdb-react-ui-kit";
import api from "../../services/api";
import moment from "moment";
import { ButtonEye } from "../../components/ButtonEye/ButtonEye";
import { RxUpdate } from "react-icons/rx";
import MaskedInput from "react-text-mask";
import { useSpring, animated } from "react-spring";
import { toast } from "react-toastify";
import {
  IoIosCheckmarkCircle,
  IoIosCloseCircle,
  IoIosHelpCircle,
} from "react-icons/io";
import ButtonGreen from "../../components/ButtonGreen/ButtonGreen";

const ProductUpdate = () => {
  const navigate = useNavigate();

  const [GTINs, setGTINs] = useState([]);
  const today = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Sao_Paulo",
  }).format(new Date());
  const [filterVisible, setFilterVisible] = useState(false);
  const [date, setDate] = useState(
    new Date().toLocaleDateString({
      region: "brazil",
    })
  );
  const [hours, setHours] = useState({
    begin: "00:00",
    end: "23:59",
  });

  useEffect(() => {
    let isMounted = true;

    async function getData() {
      try {
        const response = await api.get(
          `/productUpdate/date?clientId=${localStorage.getItem(
            "client_id"
          )}&date=${moment.utc(today).format("YYYY-MM-DD")}`
        );

        const gtins_aux = [];

        response.data.forEach((item) => {
          console.log(item);
          gtins_aux.push({
            transaction_id: item.transactionId,
            product_gtin: item.product.product_gtin,
            product_title: item.product.product_title,
            product_new_price: item.product.product_price,
            product_old_price: item.product.product_old_price,
            msg_timestamp: item.product.msg_timestamp,
            number_esls: item.numbers.total,
            status:
              item.numbers.H > 0
                ? "erro"
                : item.numbers.P > 0
                ? "em andamento"
                : "sucesso",
            success_percentage:
              item.numbers.total !== 0
                ? Math.floor(
                    (item.numbers.K * 100) / item.numbers.total
                  ).toString() + "%"
                : "Sem etiquetas vinculadas",
          });
        });

        setGTINs(gtins_aux);

        if (isMounted) setTimeout(getData, 5000);
      } catch (error) {
        toast.error("Erro ao recuperar os produtos atualizados");
        console.log(error);
      }
    }

    getData();

    return () => {
      isMounted = false;
    };
  }, []);

  // Mostrar na tabela com a situação de atualização de cada uma
  // Entao no backend, a parte que ta implementada dentro do socket deve virar algo separa em uma URL, pois sera chamada novamente para ver qual o status de cada ESL
  // Essa URL deve ser chamada a cada 5 segundos, por exemplo, para atualizar o status de cada ESL

  // const [expandedRows, setExpandedRows] = useState([]);
  // const [expandedRowsESLs, setExpandedRowsESLs] = useState([]);

  // const toggleRow = (product_gtin) => {
  //   const newExpandedRows = [...expandedRows];
  //   const rowIndex = newExpandedRows.indexOf(product_gtin);

  //   if (rowIndex >= 0) {
  //     newExpandedRows.splice(rowIndex, 1);
  //   } else {
  //     newExpandedRows.push(product_gtin);
  //   }

  //   setExpandedRows(newExpandedRows);
  // };

  // const toggleRowESL = (mac) => {
  //   const newExpandedRowsESLs = [...expandedRowsESLs];
  //   const rowIndex = newExpandedRowsESLs.indexOf(mac);

  //   if (rowIndex >= 0) {
  //     newExpandedRowsESLs.splice(rowIndex, 1);
  //   } else {
  //     newExpandedRowsESLs.push(mac);
  //   }

  //   setExpandedRowsESLs(newExpandedRowsESLs);
  // };

  function handleToDetailsEan(
    ean,
    product_name,
    old_price,
    new_price,
    transaction_id,
    transaction_time
  ) {
    console.log();
    navigate(`/eslUpdate/${transaction_id}`, {
      state: {
        product_name,
        new_price,
        old_price,
        ean,
        transaction_time,
      },
    });
  }

  function handleFilter() {
    setFilterVisible(!filterVisible);
  }

  function handleSendFilter() {
    const hours_begin = parseFloat(hours.begin.replace(":", "."));
    const hours_end = parseFloat(hours.end.replace(":", "."));

    if (hours_begin >= 23.6 || hours_end >= 23.6)
      toast.error("Horário inválido");
    else if (hours_begin > hours_end)
      toast.error("Horário de início deve ser menor que o de fim");
    else {
      const date_day = parseInt(date.substring(0, 2));
      const date_month = parseInt(date.substring(3, 5));
      const date_year = parseInt(date.substring(6));

      if (date_day > 31 || date_day < 1) toast.error("Dia inválido");
      else if (date_month > 12 || date_month < 1) toast.error("Mês inválido");
      else if (date_year < 1) toast.error("Ano inválido");

      navigate(`/productUpdateFilter/filter`, {
        state: {
          dateFilter: `${date_year}-${date_month}-${date_day}`,
        },
      });
    }
  }

  return (
    <Container>
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: 16,
          }}
        >
          <h3>Atualizações de preços de produtos</h3>
        </div>

        {filterVisible ? (
          <button
            className="material-symbols-outlined"
            style={{ borderStyle: "none", backgroundColor: "transparent" }}
            onClick={handleFilter}
          >
            close
          </button>
        ) : (
          <button
            className="material-symbols-outlined"
            style={{ borderStyle: "none", backgroundColor: "transparent" }}
            onClick={handleFilter}
          >
            filter_alt
          </button>
        )}
      </div>

      <h4>{moment.utc(today).format("DD/MM/YYYY")}</h4>

      <MDBRow
        className="text-start"
        style={{ display: filterVisible ? "flex" : "none", marginTop: 16 }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 8,
          }}
        >
          {/* <h4 style={{ marginLeft: 16 }}>Horário:</h4>
          <MaskedInput
            type="text"
            name="hours-begin"
            mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
            value={hours.begin}
            onChange={(e) => {
              setHours({
                ...hours,
                begin: e.target.value
              })
            }}
            placeholder="00:00"
            style={{ maxWidth: 80, textAlign: 'center' }}
          />
          <span>até</span>
          <MaskedInput
            type="text"
            name="hours-end"
            mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
            value={hours.end}
            onChange={(e) => {
              setHours({
                ...hours,
                end: e.target.value
              })
            }}
            placeholder="23:59"
            style={{ maxWidth: 80, textAlign: 'center' }}
          /> */}
        </div>
        <div style={{ display: "flex", flexDirection: "row", maxWidth: 200 }}>
          <div>
            <label
              htmlFor="date"
              style={{ color: "#222020", fontWeight: "600", fontSize: 14 }}
            >
              Data
            </label>
            <MaskedInput
              type="text"
              name="date"
              mask={[
                /[0-9]/,
                /[0-9]/,
                "/",
                /[0-9]/,
                /[0-9]/,
                "/",
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
                /[0-9]/,
              ]}
              value={date}
              onChange={(e) => {
                setDate(e.target.value);
              }}
              placeholder="Informe a data"
              style={{
                textAlign: "initial",
                borderWidth: 1,
                borderRadius: 8,
                borderColor: "#BAB6BC",
                paddingTop: 4,
                paddingBottom: 4,
                paddingLeft: 12,
              }}
            />
          </div>
          <ButtonGreen
            type="submit"
            onClick={handleSendFilter}
            style={{
              maxWidth: 100,
              marginTop: 20,
              marginLeft: 24,
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
              fontSize: 14,
              marginBottom: 10,
            }}
            label="Filtrar"
          />
        </div>
      </MDBRow>

      <MDBTable align="start" hover>
        <MDBTableHead>
          <tr>
            <th>Id da transação</th>
            <th>Nome do produto</th>
            <th>Preço antigo</th>
            <th>Preço novo</th>
            <th>Início da atualização</th>
            <th>Número de etiquetas</th>
            <th>Status de atualização</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {GTINs.map((EAN) => (
            <tr
              key={EAN.transaction_id}
              onClick={() =>
                handleToDetailsEan(
                  EAN.product_gtin,
                  EAN.product_title,
                  EAN.product_old_price,
                  EAN.product_new_price,
                  EAN.transaction_id,
                  moment.utc(EAN.msg_timestamp).format("HH:mm:ss - DD/MM/YYYY")
                )
              }
              style={{ cursor: "pointer", alignContent: "center" }}
            >
              <td>{EAN.transaction_id}</td>
              <td onMouseEnter={() => "oi"}>{EAN.product_title}</td>
              <td>
                {EAN.product_old_price
                  ? `R$${EAN.product_old_price.toFixed(2).replace(".", ",")}`
                  : "Sem preço"}
              </td>
              <td>
                {EAN.product_new_price
                  ? `R$${EAN.product_new_price.toFixed(2).replace(".", ",")}`
                  : "Sem preço"}
              </td>
              <td>{moment.utc(EAN.msg_timestamp).format("DD/MM/YYYY")}</td>
              <td>{EAN.number_esls}</td>
              <td>
                <span
                  style={{
                    marginRight: 4,
                    color:
                      EAN.status === "sucesso"
                        ? "#41A017"
                        : EAN.status === "erro"
                        ? "#B42A27"
                        : "#C9A21B",
                  }}
                >
                  {EAN.success_percentage}
                </span>
                <span
                  style={{
                    color:
                      EAN.status === "sucesso"
                        ? "#41A017"
                        : EAN.status === "erro"
                        ? "#B42A27"
                        : "#C9A21B",
                  }}
                >
                  {EAN.status === "sucesso" ? (
                    <IoIosCheckmarkCircle size={20} />
                  ) : EAN.status === "erro" ? (
                    <IoIosCloseCircle size={20} />
                  ) : (
                    <IoIosHelpCircle size={20} />
                  )}
                </span>
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </Container>
  );
};

export default ProductUpdate;
